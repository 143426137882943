.dashboard {
  &-pie_graph {
    display: flex;
    justify-content: space-evenly;

    div {
      width: 100%;
      border: 1px solid red;
    }

    // &:first-child{
    //   height: 30vh;
    // }

    div + div {
      padding: 1rem 2rem;

      h1 {
        font-size: 1.5rem;
        margin: 0 0 1rem;
      }
    }
  }


}
