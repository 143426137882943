.subject_form {
  &-wrapper {
    padding: 1rem 2rem 2rem;

    p {
      font-weight: 400;
    }

    .react-options {
      margin-bottom: 1rem;
    }

    .img-cover {
      max-width: 80px;
      border: 1px solid black;
      border-radius: 5px;

      img {
        width: 100%;
        object-fit: fill;
      }
    }

    input {
      margin-bottom: 1rem;
    }

    .delete-btn{
      text-align: end;
    }

    .submit-btn{
      color: white !important;
    }
  }
}
