.teacher_payment{

    .add_payment{
        text-align: end;
        margin: 0 0 1rem;
    }

    &-form{
        padding: 0 2rem;
        .btn-save{
            text-align: end;
            margin: 1rem 0;
        }
    }
    
    .payment_status_form{
        padding: 0 2rem;
        
        .form-control{
            margin: 1rem 0;
        }

        .btn-save{
            text-align: end;
            margin: 1rem 0;
        }
    }

    &-list{
        table{

            tbody tr:nth-of-type(even) {
                background-color: rgb(241, 238, 238);
              }
          
              thead tr th{
                font-size: 1rem;
                font-weight: bold;
                white-space: nowrap;
            }
            .teacher_name{
                h1{
                    white-space: nowrap;
                    font-size: 1rem;
                    margin: 0;
                    font-weight: 600;
                }
            }
            .payment_value{
                h1{
                    white-space: nowrap;
                    font-size: 1rem;
                    margin: 0;
                }
            }
            .payment_description{
                
                h1{
                    // white-space: nowrap;
                    font-size: 1rem;
                    margin: 0;
                    width: 300px;
                }
            }
            .payment_status{
                h1{
                    white-space: nowrap;
                    font-size: 0.8rem;
                    margin: 0;
                    border: 1px solid rgb(175, 175, 175);
                    text-align: center;
                    padding: 0.3em;
                    border-radius: 5px;
                    color: white;
                    font-weight: 600;
                    text-transform: capitalize;
                    width: 110px;
                }

                .pending{
                    background-color: #ffbd00;
                }

                .accept{
                    background-color: #00e676;
                }

                .reject{
                    background-color: red;
                }
            }

            .actions{
                display: flex;
                justify-content: center;
                align-items: center;

                i{
                    font-size: 1.2rem;
                    color: green;
                    cursor: pointer;
                    transition: 200ms ease-in-out;
                    position: relative;
                    
                    &:hover{
                        transform: scale(1.3);
                    }
                }
                
               
            }
        }
    }
}