.topic {
  white-space: nowrap;
  // border: 1px solid black;
  padding: 0 0.5rem;
  max-width: 100%;
  overflow: auto;

  table thead tr th {
    font-size: 1rem;
  }

  .play-video {
    cursor: pointer;
    border: 1px solid black;
    padding: 0.3rem;
    border-radius: 5px;
    color: black;
    position: relative;
    z-index: 1;
    text-transform: capitalize;
  }

  .play-video:hover {
    box-shadow: 1px 1px 10px 1px rgb(83, 82, 82);
    font-weight: 500;
  }

  .topic-img {
    max-width: 50px;
    border: 1px solid rgb(97, 96, 96);
    padding: 0.1rem;
  }

  .play-icon {
    font-size: 1rem;
    fill: #1d8cf8;
  }

  .topic-title {
    cursor: pointer;
    position: relative;
    font-size: 0.9rem;
    font-weight: 500;
  }

  .topic-title::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 3px solid #1d8cf8;
    height: 23px;
    transform: scaleX(0);
    transform-origin: left;
    transition: 300ms ease-in-out;
  }

  .topic-title:hover::before {
    transform: scaleX(1);
    font-weight: 700;
  }

  &-cell_value {
    h1 {
      font-size: 1rem;
      margin: 0;
    }
  }

  .topic_status {
    h1 {
      font-size: 1rem;
      margin: 0;
    }

    .active {
      color: green;
    }

    .in-active {
      color: red;
    }
  }
}
