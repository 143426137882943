.edit-topic {
  
  .add-description-btn {
    display: flex;
  }

  .edit_description {
    margin-bottom: 1rem;
    border: 1px solid rgb(14, 13, 13);
    border-radius: 5px;
    max-width: 100%;

    .row {
      padding: 0 1rem;

      & div:first-child {
        display: flex;
        align-items: center;
        h1 {
          margin: 0;
        }
      }

      & div:nth-child(2) {
        text-align: end;
      }
    }

    @media only screen and (max-width: 768px) {
      .row div:nth-child(2) {
        text-align: unset;
      }
    }

    .edit_description-header {
      background-color: rgb(197, 196, 196);

      h1 {
        font-size: 1.2rem;
      }
    }

    &-container {
      padding: 1rem;
      height: 300px;
      overflow-y: scroll;

      h1 {
        font-size: 1rem;
      }
    }
  }
}
