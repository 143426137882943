.bread_crumbs {
  padding: 0.5rem;
  border: 1px solid rgb(223, 222, 222);
  background-color: white;
  margin: 0 0 0.5rem;

  &-list {
    display: flex;
    align-items: center;

    .links {
      font-size: 0.95rem;
      color: rgb(105, 104, 104);
      transition: 200ms ease-in-out;
      font-weight: 500;
      position: relative;
    }

    .links:hover {
      color: black;
      text-decoration: underline;
    }

    i {
      font-size: 1rem;
      padding: 0 0.4rem;
    }

    .links:last-child {
      color: black;
    }
  }
}