.date_filter {
  padding: 0;

  & > div {
    padding: 0;
    margin: 0.5rem 0 0;
    input {
      width: 98%;
      margin: 0 0 0.5rem;
    }
  }

  & > :last-child {
    display: flex;

    button {
      margin: 0 0.5rem 1rem;
      white-space: nowrap;
    }

    button:first-child {
      margin: 0 0 1rem;
    }
  }
}
