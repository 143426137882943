.quiz_wrapper {
  .delete-quiz {
    padding: 1rem 2rem;

    h1 {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2rem;
      margin: 0 0 3rem;
    }

    .delete-btn {
      text-align: end;
    }
  }
}
