.refer_points_record {
  &-list {
    .title {
      h1 {
        font-size: 1rem;
        text-transform: capitalize;
        white-space: nowrap;
        margin: 0;
      }
    }

    .user_data {
      display: flex;
      justify-content: space-around;
      border: 1px solid black;
      align-items: center;
      padding: 0.5rem 0;
      background-color: rgb(230, 227, 227);

      div {
        display: flex;
        
        h1 {
          font-size: 1rem;
          margin: 0;
        }
        h1:first-child {
          font-size: 1rem;
          font-weight: bold;
          padding-right: 0.5rem;
        }
      }

      
      @media only screen and (max-width: 850px) {
        flex-direction: column;
        align-items: flex-start;
        
        div {
          padding: 0.2rem 1rem;
        }
      }
    }
    
    .total_points {
      padding: 0 0.3rem;
      font-size: 1rem;
      background-color: rgb(251, 225, 152);
      border: 1px solid rgb(0, 0, 0);
      margin: 1rem 0;
      width: fit-content;
      font-weight: 500;
      margin-left: auto;
    }
  }
}
