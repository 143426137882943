.quiz_list {
  table {
    tbody tr:nth-of-type(even) {
      background-color: rgb(241, 238, 238);
    }

    thead tr th{
      font-size: 1rem;
      font-weight: bold;
      white-space: nowrap;
    }

    .quiz_title {
      cursor: pointer;
      position: relative;
      width: fit-content;

      h1 {
        font-size: 1rem;
        margin: 0;
        text-transform: capitalize;
        white-space: nowrap;
        font-weight: 500;
      }
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 3px solid #1d8cf8;
        height: 30px;
        transform: scaleY(0);
        transform-origin: bottom;
        transition: 200ms ease-in-out;
      }
      
      &:hover::before {
        height: 22px;
        transform: scaleY(1);
        font-weight: 700;
      }
    }
    
    .quiz_value {
      h1 {
        font-size: 1rem;
        margin: 0;
        white-space: nowrap;
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .in-active {
      h1 {
        font-size: 1rem;
        margin: 0;
        color: red;
        white-space: nowrap;
      }
    }
    
    .active {
      h1 {
        font-size: 1rem;
        white-space: nowrap;
        margin: 0;
        color: green;
      }
    }
  }
}
