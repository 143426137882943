.topic_form {
  form {
    input, textarea {
      padding: 0.5rem;
      border: 1px solid black;
      border-radius: 2px;
      width: 100%;
    //   margin: 0 1rem;
    }
  }
}
