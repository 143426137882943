.chapter-preview {
  .description {
    margin-top: 1rem;
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px black;

    &-heading {
      font-size: 20px;
      text-align: center;
      padding: 0.2rem 0;
      background-color: rgb(185, 183, 183);
      font-weight: bold;
    }

    &-content {
      height: 300px;
      overflow-y: scroll;
      padding: 0.5rem 1rem 1rem;

      p {
        font-size: 1.1rem;
        font-weight: 500;
      }
    }
  }

  .play {
    margin-top: 0.6rem;

    .play-btn {
      width: 100%;
      font-size: 1rem;

      .play-icon {
        font-size: 1.2rem;
        margin-left: 5px;
      }
    }
  }

  .video-player {
    margin: 1rem 1.5rem 2rem;
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px black;
  }

  .topic-description {
    border: 1px solid black;
    border-radius: 5px;
    height: 300px;
    margin: 1rem 1.5rem 2rem;
    overflow: auto;
    padding: 1rem;
    box-shadow: 1px 1px 5px 1px black;
  }

  &-tabs {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    // border: 1px solid blue;

    h1 {
      border-top: 1px solid black;
      // border-radius: 5px;
      // margin: 0 1rem !important;
      width: 100%;
      font-size: 1.2rem;
      // font-weight: 500;
      text-align: center;
      padding: 0.5rem;
      margin: 0;
      cursor: pointer;
      position: relative;
      z-index: 1;
      transition: 200ms ease-in-out;
    }

    // h1::before {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   left: 0;
    //   bottom: 0;
    //   background-color: gray;
    //   z-index: -1;
    //   transform-origin: bottom;
    //   transition: 300ms ease-in-out;
    //   transform: scaleY(0);
    // }

    // h1:hover::before,
    // h1:focus::before {
    //   transform: scaleY(1);
    // }

    // h1:hover {
    //   color: white;
    // }

    .active {
      // background-color: gray;
      // color: white;
      border: 1px solid black;
      border-top: none;
      font-weight: bold;
      // border-left: 1px solid black;
      // border-right: 1px solid black;
    }
  }
}
