.update-uniCode-wrapper {
  nav {
    display: flex;
    position: relative;
    // border: 1px solid rgb(158, 155, 155);

    p {
      padding: 0.5rem 0;
      width: 100%;
      border: 1px solid rgb(158, 155, 155);
      text-align: center;
      font-weight: 600;
      transition: 200ms ease-in-out;
      background-color: rgb(232, 232, 232);

      &:hover {
        border: 2px solid blue;
        transform: scale(1.03);
      }
    }

    .active {
      transform: scale(1.03);
      border: 2px solid blue;
    }
  }

  .actions{
      margin: 1rem 0 0;
  }

  .uniCode {
    margin: 1rem 0 0;
    border: 1px solid black;
    height: 500px;
    overflow-y: scroll;
    box-shadow: 0 0 5px 0 black;
    padding: 1rem 2rem;
  }

  .uniCode-list {
    position: relative;
    font-size: 1rem;
    padding: 0.3rem 1rem 0.3rem 0;
    margin: 0.5rem 0;
    border: 1px solid rgb(45, 45, 45);
    border-radius: 5px;
    color: white;
    background-color: rgb(45, 45, 45);
    display: flex;
    justify-content: space-between;

    i {
      padding: 0 1rem;
    }

    p {
      font-size: 1rem;
      color: white;
      text-align: end;
    }

    .fa-bars {
      cursor: pointer !important;
    }
  }
}
