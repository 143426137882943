.live_session {
  &-list {
    .session_card {
      border: 1px solid rgb(192, 191, 191);
      margin: 1rem 0;
      padding: 0.3rem;
      border-radius: 5px;
      box-shadow: 1px 1px 5px 1px rgb(190, 190, 190);
      display: flex;
      height: 150px;

      .image {
        max-width: 150px;
        max-height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .info {
        h1 {
          padding: 0.5rem 0 0 1rem;
          font-size: 1rem;
          color: rgb(112, 111, 111);
          font-weight: bold;
          margin: 0 0 0.2rem;
          white-space: nowrap;
        }

        .row_data {
          display: flex;
          justify-content: space-between;
          .date {
            padding-left: 1rem;
            display: flex;
            align-items: center;

            i {
              font-size: 0.9rem;
              color: rgb(129, 131, 252);
            }

            p {
              font-size: 0.9rem;
              font-weight: 500;
              margin-left: 0.5rem;
              white-space: nowrap;
              width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .actions {
          padding: 0.3rem 0 0 1rem;
          display: flex;
          align-items: center;

          .edit {
            font-size: 1.2rem;
            margin-right: 0.5rem;
            color: green;
            cursor: pointer;
          }

          .in-active {
            margin-right: 0.5rem;
            font-size: 1.2rem;
            color: red;
            cursor: pointer;
          }

          .active {
            margin-right: 0.5rem;
            font-size: 1.2rem;
            color: green;
            cursor: pointer;
          }

          button {
            border: 1px solid black;
            transition: 100ms ease-in-out;
            font-weight: 500;
            position: relative;
            background-color: #344675;
            color: white;
            border-radius: 2px;
            white-space: nowrap;
          }

          button:hover {
            box-shadow: 1px 1px 5px 1px black;
          }

          .join_now {
            border: 1px solid black;
            transition: 300ms ease-in-out;
            font-weight: 500;
            position: relative;
            background-color: #344675;
            color: white;
            border-radius: 5px;
            padding: 0 0.2rem;
            cursor: pointer;
          }
        }

        .status {
          margin: 0.2rem 0 0 1rem;
          display: flex;
          justify-content: space-between;

          .join_now {
            border: 1px solid black;
            transition: 100ms ease-in-out;
            font-weight: 500;
            position: relative;
            background-color: #344675;
            color: white;
            border-radius: 2px;
            padding: 0 0.2rem;
            cursor: pointer;
          }

          .join_now:hover {
            box-shadow: 1px 1px 5px 1px black;
          }

          p {
            border-radius: 5px;
            border: 1px solid rgb(180, 180, 180);
            padding: 0 0.5rem;
            margin: 0;
            font-size: 0.8rem;
            font-weight: bold;
            -webkit-box-shadow: 0 10px 8px -8px #777;
            -moz-box-shadow: 0 10px 8px -8px #777;
            box-shadow: 0 10px 8px -8px #777;
          }

          .not_live {
            background-color: rgb(111, 255, 111);
            color: black;
          }

          .live {
            color: white;
            background-color: red;
            animation: blinking 1s ease-in-out infinite;
            width: 120px;
            text-align: center;
          }

          @keyframes blinking {
            0% {
              box-shadow: 0 0 1px 0 rgb(245, 152, 152);
            }
            100% {
              box-shadow: 0 0 5px 2px rgb(172, 7, 7);
            }
          }

          .expired {
            background-color: rgb(190, 190, 190);
          }
        }
      }
    }

    .no_data {
      font-size: 1.2rem;
      margin-left: 2rem;
    }

    .session-status {
      padding: 1rem 2rem;

      h1 {
        font-size: 1.5rem;
      }
    }

    @media only screen and (max-width: 950px) {
      .session_card {
        height: 230px;

        .image {
          max-width: 200px;
          max-height: 100%;

          img {
            width: 200px;
            height: 200px;
          }
        }

        .status {
          flex-direction: column;

          .join_now {
            margin: 0 0 0.2rem;
          }
        }

        .info {
          .row_data {
            flex-direction: column;
          }
        }
      }
    }

    // @media only screen and (max-width: 550px) {
    //   .session_card {
    //     height: 200px;

    //     .image {
    //       max-width: 200px;
    //       max-height: 100%;

    //       img {
    //         width: 100%;
    //         height: 200px;
    //       }
    //     }

    //     .info {
    //       .row_data {
    //         flex-direction: column;
    //       }
    //     }
    //   }
    // }
  }
}
