.sequence {
  .update_btn {
    text-align: end;

    button {
      color: white;
    }
  }

  &-list {
    height: 70vh;
    overflow-y: scroll;
    // border: 1px solid black;
    box-shadow: 0 0 5px 0 black;
    padding: 1rem 2rem;
    margin: 1rem 10rem;

    .topic_quiz_list {
      position: relative;
      font-size: 1rem;
      padding: 0.3rem 1rem 0.3rem 0;
      margin: 0.5rem 0;
      border: 1px solid rgb(45, 45, 45);
      border-radius: 5px;
      color: white;
      background-color: rgb(45, 45, 45);
      display: flex;
      justify-content: space-between;

      i {
        padding: 0 1rem;
      }

      div:first-child {
        width: 270px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-size: 1rem;
        color: white;
        text-align: end;
      }
    }

    @media only screen and (max-width: 800px) {
      margin: 1rem 2rem;
    }
  }
}
