.teacher_profile{
    
    &-content{
        form{
            .profile_image{
                max-width: 100px;
                img{
                    border-radius: 50%;
                    border: 1px solid black;
                    width: 100%;
                    height: 100px;
                    border-radius: 50%;
                    padding: 0.2rem;
                    object-fit: fill;
                }
            }
        }
    }
}