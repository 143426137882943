.report {
  .search-filter {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    position: relative;

    select,
    input {
      padding: 0.5rem 2rem 0.5rem 0.5rem;
    }

    div + div {
      margin-left: 2rem;
    }

    .to-from {
      display: flex;
    }

    div + div + p {
      position: absolute;
      right: 0;
      border: 1px solid white;
      border-radius: 5px;
      padding: 0 0.5rem;
    }

    .payment-status {
      display: flex;
      align-items: center;
      width: 220px;
      white-space: nowrap;
    }
  }

  @media only screen and (max-width: 820px) {
    .search-filter {
      display: unset;
      margin-bottom: 1rem;

      div > select {
        width: 100%;
      }

      div {
        margin-bottom: 10px;
      }

      div + div {
        margin-left: 0;
      }

      .to-from > div + div {
        margin-left: 10px;
      }

      div + div + p {
        position: unset;
        width: fit-content;
        margin: 1rem 0 0.5rem;
      }

      &[datatype='byPurchaseDate'] {
        div + div + p {
          margin-top: -1.5rem;
        }
      }
    }
  }
}
