.student_detail{
    margin:  0 0 2rem;

    &-wrapper{
    // display: flex;

    .profile_img{
        max-width: 120px;
        border-radius: 5px;
        height: fit-content;
        border: 1px solid rgb(109, 108, 108);
       -webkit-box-shadow: 0 10px 6px -6px #777;
       -moz-box-shadow: 0 10px 6px -6px #777;
       box-shadow: 0 10px 6px -6px #777;
    //    margin: 0 auto;

       img{
            width: 100%;
            object-fit: fill;
        }
    }

    .student-content{
        width: 100%;
        border: 1px solid rgb(209, 208, 208);
        border-radius: 5px;
        margin: 1rem 0 0;
        background-color: rgb(233, 233, 233);
        
        .info{
            display: flex;
            justify-content: space-between;
            padding: 0 1rem;
            
            .left-part{
                display: flex;
                align-items: center;
                
                p{
                    margin: 0;
                    font-size: 1.2rem;
                }
                h1{
                    margin: 0;
                    font-size: 1.2rem;
                    font-weight: 600;
                    margin-left: 1rem;
                }
            }
            
            .right-part{
                display: flex;
                align-items: center;
                width: 50%;
                
                p{
                    margin: 0;
                    font-size: 1.2rem;
                }
            h1{
                margin: 0;
                font-size: 1.2rem;
                font-weight: 600;
                margin-left: 1rem;
            }
        }
    }
        }

        }

    @media only screen and (max-width: 750px) {
         &-wrapper{ 
            .student-content{
                width: 100%;
                
                .info{
                    flex-direction: column;

                    .right-part{
                        width: unset;
                    }
                }
            }
        }
      }
}