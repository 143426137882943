.uniCode_wrapper {
  &_tabs {
    display: flex;
    text-align: center;

    .topic {
      border: 1px solid black;
      width: 50%;
      margin: 0.5rem;
      transition: 200ms ease-in-out;
      cursor: pointer;
      h1 {
        font-size: 1.2rem;
        margin: 0;
        padding: 0.5rem 0;
        font-weight: 600;
      }
    }

    .quiz {
      border: 1px solid black;
      width: 50%;
      margin: 0.5rem;
      transition: 200ms ease-in-out;
      cursor: pointer;
      h1 {
        padding: 0.5rem 0;
        font-size: 1.2rem;
        margin: 0;
        font-weight: 600;
      }
    }

    .topic:hover {
      box-shadow: 2px 2px 5px 2px rgb(105, 104, 104);
    }

    .quiz:hover {
      box-shadow: 2px 2px 5px 2px rgb(105, 104, 104);
    }

    .activeBtn {
      box-shadow: 2px 2px 5px 2px rgb(105, 104, 104);
    }
  }

  &-list {
    table {
      tbody tr:nth-of-type(even) {
        background-color: rgb(241, 238, 238);
      }

      thead tr th {
        font-size: 1rem;
        font-weight: bold;
        white-space: nowrap;
      }

      .name {
        h1 {
          font-size: 1.3em;
          margin: 0;
          font-weight: 500;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .uniCode_value {
        h1 {
          font-size: 1.3em;
          margin: 0;
          font-weight: 500;
        }
      }
    }
  }
}
