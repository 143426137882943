.advertisement {
  &-btn {
    text-align: end;
    margin-bottom: 1rem;
  }

  &_container {
    display: flex;
    justify-content: center;

    ._card {
      border: 1px solid black;
      padding: 0.3rem;
      margin: 1rem;
      width: fit-content;
      border-radius: 5px;
      box-shadow: 1px 1px 10px 1px rgb(145, 144, 144);

      .image {
        max-width: 300px;
        height: auto;
        margin: 0 0 1rem;
        -webkit-box-shadow: 0 15px 6px -6px #777;
        -moz-box-shadow: 0 10px 6px -6px #777;
        box-shadow: 0 10px 6px -6px #777;
        
        img {
          border: 1px solid rgb(201, 199, 199);
          border-radius: 5px;
          width: 100%;
        }
      }

      .data {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-size: 1rem;
          text-transform: capitalize;
        }

        h1 {
          font-size: 1rem;
          text-transform: capitalize;
          font-weight: bold;
          margin: 0;
          padding-left: 0.2rem;
        }
      }

      .update_status,
      .update_image {
        button {
          width: 100%;
        }
        width: 100%;
        text-align: center;
      }

      .update_status {
        margin-top: 1rem;
      }
    }
  }

  table {
    width: 100%;
    border: 1px solid black;

    th {
      font-size: 1rem !important;
      background-color: #44a3ff;
      padding: 0.5rem 1rem;
      color: white !important;
    }

    td {
      font-size: 1rem !important;
      padding: 0.5rem 1rem;
      text-transform: capitalize;
      font-weight: bold;
    }
  }

  .upload_image {
    display: none;
  }
}
