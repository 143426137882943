.grade_img {
  margin: 1rem 0 0.5rem;
  padding: 0.2rem;
  max-width: 100px;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px black;

  img{
    width: 100%;
    object-fit: fill;
  }
}
