.chapter_sequence {
  &-note {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;

    .important_note, .user_guide {
      h1{
        font-size: 1rem;
        font-weight: bold;
        margin: 0;
      }
      
      h1::after{
        content: ":";
      }

      p{
        font-size: 1rem;
        font-weight: 500;
        margin: 0;

        i{
          padding: 0 0.5rem;
          color: pink;
        }
      }
    }

    .important_note{
      padding: 0 2rem;
    }
  }
}
