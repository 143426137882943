.bundle-rate {
  .card {
    padding-bottom: 2.5rem;
  }

  &__header {
    display: flex;
    align-items: center;

    .icon {
      font-size: 36px;
      cursor: pointer;
    }
  }

  &__form {
    .date-select {
      margin: 0 10%;

      select {
        width: 100%;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
      }

      p {
        margin-bottom: 0.2rem;
      }

      input {
        margin-bottom: 0.5rem;
        padding: 6px 10px;
      }
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
