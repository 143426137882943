.user_platforms {
  .graph {
    border: 2px solid black;
    margin: 1rem 0 0;

    .box {
      display: flex;
      justify-content: space-between;
      // margin: 0 0 2rem;
      background-color: rgb(204, 228, 250);
      // box-shadow: 0 3px 5px 0 black;
      & div:last-child {
        border-bottom: 1px solid black;
      }

      &_card {
        margin: 1rem;
        display: flex;
        width: fit-content;
        border: 1px solid black;
        background-color: rgb(231, 225, 225);
        padding: 0 1rem;
        height: fit-content;

        p: {
          font-size: 1.5rem;
        }
        & > p:first-child {
          font-weight: bold;
        }
        & > p:last-child {
          padding: 0 0 0 0.3rem;
        }

        .inputField {
          padding: 0.5rem 0;
          display: flex;
          align-items: end;
          margin-right: 0.5rem;

          .threshold {
            margin-right: 0.5rem;

            input {
              padding: 0.5rem;
              border: 1px solid rgb(169, 168, 168);
            }
          }

          button {
            margin: 0;
            white-space: nowrap;
            padding: 0.65rem 2rem;
          }
        }
      }
    }

    .heading {
      margin: 2rem 0 0;
      font-size: 1.5rem;
      text-align: center;
    }

    .gap {
      margin: 0 0 3rem;
    }
  }

  .search_block {
    border: 1px solid rgb(105, 104, 104);
    padding: 0 0.5rem 0.5rem;
    background-color: rgb(219, 224, 223);

    .col-md-4:first-child {
      border-right: 1px solid rgb(186, 184, 184);
    }

    input {
      background-color: white;
    }

    .search_uniCode {
      margin: 1rem 0;

      select {
        background-color: white;

        option {
          font-weight: 500;
          color: black;
        }
      }
    }
  }

  .search-by-id {
    // display: flex;
    // flex-direction: column;

    input {
      margin-right: 1rem;
    }

    .btn_grp {
      display: flex;

      button {
        width: 100%;
        color: white;
      }
    }
  }

  table {
    tbody tr:nth-of-type(even) {
      background-color: rgb(241, 238, 238);
    }

    thead tr th {
      font-size: 1rem;
      font-weight: bold;
      white-space: nowrap;
    }

    tbody tr td {
      .value {
        h1 {
          font-size: 1rem;
          white-space: nowrap;
        }
      }
    }

    // .MuiMenu-paper{
    //   z-index: 999999999999999999;
    // }
  }

  .downloadsBtn {
    display: flex;
    justify-content: flex-end;
  }

  @media only screen and (max-width: 700px) {
    & .graph .box {
      flex-direction: column;
    }
  }
}
