.comments {
  .card {
    padding: 0;
  }

  .card-title {
    margin: 0;
    padding: 0;
  }

  .card-body {
    padding: 0;
  }

  .filter {
    padding: 0 0.5rem;
    text-align: end;

    button {
      padding: 0.2rem 1rem;
      border: 2px solid rgb(192, 192, 192);
      transition: 200ms ease-in-out;
      background-color: unset;
      border-radius: 5px;
      margin: 0 0 1rem;
      font-weight: 500;
      font-size: 1rem;

      &:hover {
        border: 2px solid #506dee;
        background-color: #506dee;
        color: white;
      }
    }
  }

  &-list {
    padding: 0 0.5rem;

    &_card {
      &:not(:first-child) {
        margin: 2rem 0;
      }

      padding: 1rem;
      border: 1px solid #cecccc;
      box-shadow: 1px 1px 2px 1px #c5c4c4;

      .details {
        display: flex;
        margin: 0 0 1rem;

        .user_profile {
          border: 4px solid #506dee;
          max-width: 100px;
          margin-right: 0.5rem;
          -webkit-box-shadow: 0 10px 6px -6px #777;
          -moz-box-shadow: 0 10px 6px -6px #777;
          box-shadow: 0 10px 6px -6px #777;

          img {
            border: 2px solid white;
            width: 100%;
          }
        }

        & > div + div {
          width: 100%;
          position: relative;
          padding-right: 1rem;

          .delete_btn {
            position: absolute;
            right: 0;
            font-size: 0.9rem;
            background-color: white;
            border: 2px solid red;
            border-radius: 5px;
            padding: 0 1rem;
            transition: 200ms ease-in-out;
            font-weight: 600;
          }

          .delete_btn:hover {
            background-color: red;
            color: white;
          }
        }

        .asked_by {
          p {
            font-size: 0.8rem;
            line-height: 1.3rem;

            span {
              font-weight: bold;
              padding-right: 0.2rem;
            }
          }
        }
      }

      .reply {
        background-color: #f0f0f0;
        display: flex;
        justify-content: space-between;
        padding: 0.8rem;

        .answers {
          border: 1px solid rgb(150, 149, 149);
          background-color: white;
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 120px;
          transition: 200ms ease-in-out;
          cursor: pointer;

          h1 {
            font-size: 1rem;
            margin: 0;
            padding: 0;
          }

          &:hover {
            box-shadow: 1px 1px 5px 1px black;
          }
        }

        .reply_btn {
          font-size: 1rem;
          font-weight: 600;
          background-color: black;
          color: white;
        }
      }

      .question {
        font-size: 1.1rem;
        font-weight: 600;
        margin: 0 0 1rem;
        text-align: justify;
        padding: 1rem;
        background-color: #f0f0f0;
        line-height: 1.5rem;

        span {
          color: black;
          font-weight: bold;
        }
      }

      .date {
        font-size: 0.8rem;
        font-weight: 500;
      }
    }

    .disabled {
      background-color: #d6d6d6;
    }
  }

  .load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;

    button {
      border: unset;
      background-color: unset;
      font-size: 1rem;
      line-height: 1.5rem;
      position: relative;
      margin: 0;
      z-index: 1;
      margin: 0 0.5rem;
    }

    button::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      border-bottom: 3px solid #1d8cf8;
      transform: scaleX(0);
      transform-origin: left;
      transition: 200ms ease-in-out;
    }

    button:hover::before {
      transform: scaleX(1);
    }

    .page_limit {
      border: 1px solid black;
      select {
        margin-left: 0.5rem;
        border: unset;
        font-size: 1rem;

        option {
          width: 50px;
        }
      }
      i {
        font-size: 1rem;
        margin: 0 0.4rem;
      }
    }

    h1 {
      font-size: 1rem;
      padding: 0 0.5rem 0;
      margin: 0;
    }
  }

  .answer_form {
    margin: 0 2rem;
  }

  .filter-comments {
    .filter_form {
      margin: 0 2rem;

      p {
        margin: 1rem 0 0;
      }
    }
  }
}
