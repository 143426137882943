.reports {
  .row {
    margin: 0;
    padding: 0;

    & > .col-md-4 {
      padding: 1rem 1rem 0 0;
    }
  }

  .total_info {
    border: 1px solid black;
    background-color: rgb(236, 236, 236);

    .col-md-4 {
      padding: 0 1rem;
    }

    div {
      padding: 0;
      display: flex;
      align-items: center;

      p {
        margin: 0;
        font-size: 1rem;
      }
      h1 {
        margin: 0;
        font-size: 1rem;
        font-weight: bold;
        padding-left: 0.5rem;
      }
    }
  }

  &_list {
    table {
      thead > tr > th {
        white-space: nowrap;
        font-size: 0.95rem;
      }

      & tbody tr:nth-of-type(even) {
        background-color: rgb(241, 238, 238);
      }

      .report_value > h1 {
        font-size: 0.9rem;
        margin: 0;
        white-space: nowrap;
      }

      & tbody tr td:last-child {
        text-transform: capitalize;
      }
    }
  }

  .average_time {
    margin: 0 0 1rem;
    border: 1px solid black;
    background-color: rgb(214, 213, 213);
    width: fit-content;
    padding: 0.5rem 1rem;

    h1 {
      font-size: 1rem;
      margin: 0;

      span {
        font-weight: 600;
      }
    }
  }

  .customDuration {
    width: 260px;

    &_value {
      justify-content: space-between;
      display: flex;

      input {
        height: 30px;
        width: 120px;
        border-radius: 2px;
      }
    }

    .filter {
      display: flex;
      justify-content: space-between;

      .filterBtn {
        // width: 100%;
        margin: 0.5rem 0 0;
      }
    }
  }
}
