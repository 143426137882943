.chapter-card {
  text-transform: capitalize;

  .chapInfo {
    display: flex;
    justify-content: space-between;

    .edit__date {
      display: flex;
      align-items: center;

      .date_field {
        width: unset;
        padding: 0 30px;
        margin: 0.2rem 0;
      }

      i {
        font-size: 1rem;
      }
    }

    .status {
      padding: 0.1rem 1rem;
      border-radius: 5px;
      border: 1px solid white;
      font-weight: 700;
      font-size: 0.8rem;

      &.bundle {
        background: darkcyan;
      }
    }
    .m_5 {
      margin-left: 5px;
    }
  }
}
