.create_quiz {
  &-title-from {
    .important_field {
      position: absolute;
      font-size: 1.1rem;
      color: red;
      padding: 0 0.2rem;
    }

  }

  &-question {
    padding: 1rem;

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
        font-size: 1.5rem;
      }

      &-point {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        h1 {
          font-size: 1.1rem;
          padding: 0 0.5rem;
        }

        button {
          padding: 0.5rem 1rem;
        }
      }
    }

    &-form {
      .form-field {
        margin-top: 2rem;

        .option-pic {
          margin-top: 0.5rem;
          display: flex;
          align-items: center;

          img {
            border: 1px solid black;
            width: 70px;
            height: 70px;
            border-radius: 10px;
            padding: 0.2rem;
          }

          input {
            border: none;
          }
        }

        .option-fields {
          margin-top: 0.5rem;
          display: flex;
          align-items: center;

          input:first-child {
            flex: 1;
            height: 1.2rem;
          }

          input:last-child {
            flex: 10;
          }
        }
      }

      .buttons {
        display: flex;
        justify-content: center;
        margin-top: 4rem;
      }
    }

    @media only screen and (max-width: 500px) {
      &-header {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .view-question-header {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 1rem;
      border: 1px solid black;
      border-radius: 5px;
      padding: 0.2rem;
      margin: 0;
      height: 2rem;
      font-weight: 500;
    }
  }

  .view_question-list {
    max-width: 100%;

    &-table {
      width: 100%;
      height: 60vh;
      overflow: scroll;
      border: 1px solid black;

      table tbody tr td {
        text-align: center;
      }

      table tr th {
        text-align: center;
        white-space: nowrap;
      }

      table tr th h4 {
        font-size: 1rem;
      }

      table tr td p {
        width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      table tr td img {
        width: 80px;
        height: 80px;
      }

      table tr td .cancel-btn {
        font-size: 2rem;
        fill: red;
        transform: rotate(45deg);
        transition: 200ms ease-in-out;
        cursor: pointer;
        border-radius: 50%;
        padding: 0.2rem;
      }

      table tbody tr td:last-child {
        display: flex;
        align-items: center;
        height: 100px;
      }

      table tr td .edit-btn {
        font-size: 2.2rem;
        fill: black;
        transition: 500ms ease-in-out;
        cursor: pointer;
        border-radius: 50%;
        margin-left: 1rem;
        padding: 0.3rem;
      }

      table tr td .cancel-btn:hover {
        transform: rotate(135deg);
        box-shadow: 1px 1px 10px 1px rgb(83, 82, 82);
      }

      table tr td .edit-btn:hover {
        transform: rotate(360deg);
        box-shadow: 1px 1px 10px 1px rgb(83, 82, 82);
      }
    }
  }
}
