.subject_wrapper {
  .card-body {
    padding: 0 15px 15px;
  }

  .add-subject-btn {
    padding: 0 2rem 1rem;
    text-align: end;
  }
}
