.subject_list {
  table {
    & tbody tr:nth-of-type(even) {
      background-color: rgb(241, 238, 238);
    }

    & thead {
      white-space: nowrap;
      // text-align: left;
    }

    & thead tr th {
      font-size: 1rem;
      // padding-left: 1.2rem;
      // text-align: center;
      text-align: center;
      font-weight: bold;
    }

    & tbody tr td {
      text-align: center;
      // text-align: left;
      white-space: nowrap;
    }

    .subject_image {
      border: 1px solid black;
      max-width: 50px;
      border-radius: 5px;
      text-align: center;

      img {
        width: 100%;
      }
    }

    .subject_text {
      h1 {
        font-size: 1rem;
        margin-bottom: 0;
      }
    }

    .subject_status {
      h1 {
        font-size: 1rem;
        margin-bottom: 0;
      }

      .active {
        color: green;
      }

      .in-active {
        color: red;
      }
    }

    .total_chapters {
      border: 1px solid black;
      border-radius: 5px;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;

      .chapter-row {
        display: flex;
        justify-content: space-between;

        h1 {
          font-size: 0.9rem;
          margin-bottom: 0;
        }
      }

      .view-more {
        border: 1px solid black;
        border-radius: 5px;
        text-align: center;
        margin-top: 0.5rem;
        cursor: pointer;
        transition: 200ms ease-in-out;
        width: 140px;
        position: relative;

        h1 {
          font-size: 1rem;
          margin: 0;
          padding: 0.2rem 0.5rem;
        }
      }

      .view-more:hover {
        background-color: rgb(230, 230, 230);

        h1 {
          font-weight: 600;
          font-size: 1rem;
        }
      }
    }

    .subject_name {
      z-index: 1;
      font-size: 0.9rem;
      margin-bottom: 0;
      cursor: pointer;
      position: relative;
      width: fit-content;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        border-bottom: 3px solid #44a3ff;
        z-index: -1;
        height: 3rem;
        transition: 300ms ease-in-out;
        transform: scaleY(0);
        transform-origin: bottom;
      }

      &:hover::before {
        height: 1.2rem;
        transform: scaleY(1);
      }
    }
  }
}
