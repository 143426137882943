.comment-answers {
  &_container {
    border: 1px solid rgb(190, 189, 189);
    padding: 0.5rem;
    box-shadow: 2px 2px 5px 2px rgb(184, 182, 182);

    .details {
      display: flex;

      .profileImg {
        max-width: 120px;
        border-radius: 5px;
        border: 4px solid rgb(126, 126, 241);
        -webkit-box-shadow: 0 10px 6px -6px #777;
        -moz-box-shadow: 0 10px 6px -6px #777;
        box-shadow: 0 10px 6px -6px #777;

        img {
          width: 100%;
          border-radius: 5px;
          border: 3px solid white;
        }
      }

      .info {
        padding-left: 1rem;
        position: relative;
        width: 100%;

        p {
          font-size: 0.8rem;
          line-height: 1.3rem;

          span {
            font-weight: bold;
          }
        }

        .action {
          position: absolute;
          top: 0;
          right: 0;
          width: 120px;
          display: flex;
          justify-content: space-between;

          button {
            font-size: 0.9rem;
            background-color: white;
            border-radius: 5px;
            padding: 0 1rem;
            transition: 200ms ease-in-out;
            font-weight: 600;
            line-height: 1.4rem;
          }

          .red {
            border: 2px solid red;
          }

          .blue {
            border: 2px solid #5558f7;
          }

          .green {
            border: 2px solid green;
          }

          .red:hover {
            background-color: red;
            color: white;
          }

          .green:hover {
            background-color: green;
            color: white;
          }

          .blue:hover {
            background-color: #5558f7;
            color: white;
          }
        }
      }
    }

    & > p {
      font-size: 0.8rem;
      border: 1px solid rgb(189, 187, 187);
      width: 120px;
      padding: 0 0.5rem;
      font-weight: bold;
      color: white;
      background-color: rgb(80, 109, 238);
      text-transform: capitalize;
      margin: 0.5rem 0;
      text-align: center;
    }

    .question-reply {
      display: flex;
      align-items: center;
      background-color: rgb(212, 212, 212);
      padding: 0.5rem;
      text-align: justify;

      h1 {
        font-size: 1.3rem;
        margin: 0;
        font-weight: bold;
        flex: 1;
        line-height: 1.8rem;
      }

      button {
        margin: 0 1rem;
        font-size: 1rem;
        font-weight: 600;
        background-color: black;
        color: white;
      }
    }
  }

  &_list {
    .bg_light {
      background-color: rgb(195, 255, 195) !important;
    }

    .answer_card {
      background-color: rgb(238, 238, 238);
      padding: 0.5rem;
      margin: 1.5rem 0;
      display: flex;
      position: relative;
      animation: fadeIn 1s ease-in-out;

      .left {
        .user_img {
          width: 120px;
          max-width: 100px;
          border-radius: 50%;
          border: 4px solid rgb(126, 126, 241);
          -webkit-box-shadow: 0 10px 6px -6px #777;
          -moz-box-shadow: 0 10px 6px -6px #777;
          box-shadow: 0 10px 6px -6px #777;

          img {
            height: 100px;
            width: 100%;
            border-radius: 50%;
            border: 3px solid white;
            object-fit: fill;
          }
        }
      }

      .right {
        padding: 0.5rem 0 0 1rem;
        position: relative;
        margin: 0 0 2rem;
        width: 100%;

        .firstRow {
          display: flex;
          align-items: center;

          .label {
            height: fit-content;
            font-size: 0.8rem;
            text-transform: capitalize;
            color: white;
            font-weight: 600;
            padding: 0 0.5rem;
            border: 1px solid rgb(207, 205, 205);
            width: 100px;
            margin-right: 1rem;
            text-align: center;
          }

          .blue {
            background-color: #506dee;
          }
          .red {
            background-color: rgb(255, 40, 40);
          }
          .orange {
            background-color: #ffbd00;
          }
          .green {
            background-color: rgb(117, 255, 117);
          }
          .purple {
            background-color: rgb(255, 108, 255);
          }

          h1 {
            margin: 0;
            font-size: 1rem;
            font-weight: unset;
            span {
              margin-right: 1rem;
              font-weight: bold;
            }
          }

          .icon {
            font-size: 1.2rem;
          }

          .check {
            fill: green;
          }
          .cross {
            fill: green;
          }
        }

        .secondRow {
          h1 {
            font-size: 1.2rem;
            margin: 1rem 0 0.2rem;
            font-weight: 600;
          }

          p {
            font-size: 1rem;
            line-height: 1.2rem;
            text-align: justify;
          }
        }

        .actions {
          margin: 0.5rem 0 0;
          display: flex;
          width: 110px;
          justify-content: space-between;
          position: absolute;
          right: 0;

          .edit_btn,
          .delete_btn,
          .revert_btn {
            font-size: 0.9rem;
            background-color: white;
            border-radius: 5px;
            padding: 0 1rem;
            transition: 200ms ease-in-out;
            font-weight: 600;
            line-height: 1.4rem;
          }

          .revert_btn, .only {
            position: absolute;
            right: 0;
          }

          .edit_btn {
            border: 2px solid rgb(85, 88, 247);
          }

          .delete_btn,
          .revert_btn {
            border: 2px solid green;
          }

          .revert_btn {
            border: 2px solid red;
          }

          .edit_btn:hover {
            background-color: rgb(85, 88, 247);
            color: white;
          }

          .delete_btn:hover {
            background-color: green;
            color: white;
          }

          .revert_btn:hover {
            background-color: red;
            color: white;
          }
        }
      }
    }

    @keyframes fadeIn {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }

    @media only screen and (max-width: 650px) {
      .answer_card {
        flex-direction: column;
      }
    }

    @media only screen and (max-width: 650px) {
      .answer_card {
        flex-direction: column;

        .right {
          .firstRow {
            margin: 1rem 0 0;
            flex-direction: column;
            align-items: flex-start;

            & > :nth-child(even) {
              margin: 0.3rem 0;
            }
          }
        }
      }
    }
  }

  .answer_form {
    margin: 0 2rem;
  }
}
