.modal {
  z-index: 999;
}

.modal_container {
  padding: 0 1rem;
  text-align: center;
  position: relative;

  .heading {
    color: unset;
  }

  .cross-btn {
    all: unset;
    font-size: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 1rem;
  }
}
