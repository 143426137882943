.student-list {
  .total_students {
    margin: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid black;
    background-color: rgb(230, 227, 227);
    padding: 0.3rem;

    div {
      display: flex;
    }

    div > h1:first-child {
      margin: 0;
      font-size: 1.1rem;
      font-weight: 500;
    }
    
    div > h1 + h1 {
      margin: 0;
      font-size: 1.1rem;
      font-weight: bold;
      padding-left: 5px;
    }
  }

  .MuiPaper-root{
    margin: 1rem 0 0;
  }

  table {
    thead > tr > th {
      white-space: nowrap;
      font-size: 0.95rem;
    }

    & tbody tr:nth-of-type(even) {
      background-color: rgb(241, 238, 238);
    }

    & tbody tr td div input {
      width: 200px;
    }
    
    .student_name > h1 {
      font-size: 1rem;
      // white-space: nowrap;
      margin: 0;
      cursor: pointer;
      transition: 100ms ease-in-out;
      font-weight: 600;
    }
    
    .student_name > h1:hover {
      color: #44a3ff;
    }
    
    .value > h1 {
      font-size: 1rem;
      white-space: nowrap;
      margin: 0;
      // width: 200px;
    }
    
    .status {
      h1 {
        font-size: 1rem;
        // white-space: nowrap;
        margin: 0;
      }
      
      .active {
        color: green;
      }
      
      .in-active {
        color: red;
      }
    }
    
    .approved-status {
      .true {
        font-size: 1.3rem;
        color: red;
        cursor: pointer !important;
      }
      .false {
        font-size: 1.3rem;
        color: green;
      }
    }
    
    .actions{
      display: flex;
      width: 80px;

      i {
        cursor: pointer !important;
        font-size: 1.3rem;
        color: green;
        width: fit-content;
      }
    } 
  }

  @media only screen and (max-width: 700px) {
    .total_students {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
