.total_quiz {
  margin: 1rem 2rem 2rem;

  &-wrapper {
    background-color: rgb(245, 241, 241);
    border: 1px solid rgb(146, 144, 144);
    border-radius: 1.3rem 1.3rem 0 0 ;
    
    .heading {
      font-size: 1.5rem;
      border-radius: 1.3rem 1.3rem 0 0 ;
      padding: 0.5rem;
      text-align: center;
      margin: 0 0 2rem;
      font-weight: 500;
      color: white;
      border-bottom: 1px solid black;
      background-color: #95959b;
    }

    .total_info {
      display: flex;
      justify-content: space-between;
      padding: 0 2rem;

      h1 {
        font-size: 1rem;
        margin: 0 0 1rem;
        // font-weight: bold;
      }
      .label {
        font-weight: bold;
      }
    }
  }
}
