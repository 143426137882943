.teacher {
  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h1 {
      padding: 0;
      margin: 0;
      font-size: 1rem;
      border: 1px solid black;
      padding: 0.2rem;
      background-color: rgb(251, 225, 152);
      font-weight: 500;
    }

    .add-teacher-btn {
      padding: 0;
      margin: 0;
      padding: 0.6rem 1rem;
    }
  }

  &-status {
    padding: 1rem 2rem;

    div {
      text-align: end;
    }
  }

  &-form {
    padding: 1rem;
    
    form {
      padding: 1rem;
      border: 1px solid rgb(208, 207, 207);
      height: 500px;
      overflow: auto;
    }

    input {
      margin-bottom: 1rem;
    }

    .profile_img {
      max-width: 100px;
      height: 100px;

      img {
        width: 100%;
      }
    }

    .save-btn {
      text-align: end;

      button {
        color: white;
      }
    }
  }

  &-list {
    table {
      & tbody tr:nth-of-type(even) {
        background-color: rgb(241, 238, 238);
      }

      thead > tr > th {
        white-space: nowrap;
        font-size: 1rem;
      }

      thead > tr > th:last-child {
        text-align: center;
      }

      .teacher_name > h1 {
        font-size: 1rem;
        white-space: nowrap;
        margin: 0;
        cursor: pointer;
        transition: 100ms ease-in-out;
        font-weight: 600;
        text-transform: capitalize;
      }

      .teacher_name > h1:hover {
        color: #44a3ff;
      }

      .value > h1 {
        font-size: 1rem;
        white-space: nowrap;
        margin: 0;
      }

      .status {
        h1 {
          font-size: 1rem;
          white-space: nowrap;
          margin: 0;
        }

        .active {
          color: green;
        }

        .in-active {
          color: red;
        }
      }

      .actions {
        text-align: center;
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto;
        width: 200px;

        .icon {
          font-size: 1.3rem;
          cursor: pointer;
          color: green;
        }

        .red {
          color: red;
        }
      }
    }
  }

  &-assign_subject {
    padding: 1rem 2rem;

    p {
      font-weight: 600;
    }

    .react-options {
      margin-bottom: 1rem;
    }

    .save-grade-btn {
      text-align: end;

      button {
        color: white;
      }
    }
  }
}
