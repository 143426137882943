.google_analytics {
  margin: 1rem 0;

  &-card {
    border: 1px solid black;

    h1 {
      font-size: 1.2rem;
      text-align: center;
      margin: 0;
      background-color: rgb(221, 220, 220);
      padding: 0.5rem;
      font-weight: bold;
      border-bottom: 1px solid black;
    }

    .table_layout {
      height: 285px;
      padding: 0 1rem;
      margin: 1rem 0;
      overflow-y: auto;

      table {
        width: 100%;
        margin-bottom: 1rem;

        thead {
          tr {
            th {
              font-size: 1rem;
              border: 1px solid rgb(165, 165, 165);
              padding-left: 1rem;
            }
          }
        }
        tbody {
          tr {
            td {
              padding-left: 1rem;
              font-size: 1rem;
              border: 1px solid rgb(165, 165, 165);
              text-transform: capitalize;
            }
          }
        }
      }
    }

    .pie_chart {
      margin: 1.5rem 0 0.5rem;
      // height: 300px;
      height: fit-content;
      padding: 1rem;
    }

    .big_pie_chart {
      margin: 1.5rem 0 0.5rem;
      // height: 580px;
      height: fit-content;
      padding: 1rem;
    }
  }
  .total_value_layout {
    border: 1px solid rgb(233, 231, 231);
    box-shadow: 1px 1px 2px 1px rgb(211, 211, 211);
    padding: 1rem;

    h1 {
      margin: 0;
    }

    .downloads {
      display: flex;
      justify-content: space-between;

      button {
        margin: 0;
        padding: 0 1rem;
      }
    }
  }

  .date_filter_modal {
    form {
      padding: 0 3rem 1rem;

      & > :nth-child(3){
        margin-top: 1rem;
      }
      
      .filter-btn {
        text-align: end;
        margin-top: 1rem;
      }
    }
  }

  &-toggle {
    & > p {
      border: 1px solid black;
      border-radius: 5px;
      padding: 0.2rem 0.6rem;
      font-size: 1rem;
      background-color: rgb(228, 228, 228);
      color: black;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;

      i {
        font-size: 1.3rem;
      }
    }

    .date-filter {
      padding: 2rem;

      .no_of_days {
        display: flex;
        justify-content: end;

        p {
          border: 1px solid black;
          background-color: rgb(255, 225, 168);
          font-size: 1rem;
          padding: 0 1rem;
          width: fit-content;

          span {
            font-weight: 600;
            padding: 0 0.3rem;
          }
        }
      }
    }

    .menu {
      border: 1px solid rgb(167, 166, 166);
      border-top: none;
      padding-bottom: 2rem;
      // border-radius: 5px;
    }
  }

  // @media only screen and (max-width: 700px) {
  //   &-card {
  //     .big_pie_chart {
  //       margin: 1.5rem 0 0.5rem;
  //       height: 580px;
  //       padding: 1rem;
  //     }
  //   }
  // }
}
