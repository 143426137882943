.notification_panel {
  &-form {
    margin: 0 1rem;

    & > p {
      margin: 1rem 0 0;
    }

    & > input,
    textarea {
      border-radius: 2px;
    }

    & > textarea {
      padding: 0 0.5rem;
      height: 100px;
    }

    & > div {
      margin: 1rem 0;
      text-align: end;

      .loader {
        border: 5px solid rgb(205, 201, 201);
        border-radius: 50%;
        border-top: 5px solid #3498db;
        width: 25px;
        height: 25px;
        animation: spin 2s linear infinite;

        -webkit-animation: spin 1.5s linear infinite; /* Safari */
        animation: spin 1.5s linear infinite;
        // top: 50%;
        // position: absolute;
        // left: 50%;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}
